import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams, Outlet } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  logoUrl,
  advertUrl,
} from "../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const ChangePwd = () => {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "รหัสผ่านต้อง 6 ตัวขึ้นไป")
      .required("ป้อนรหัสผ่าน"),
    confirmPassword: Yup.string()
      .min(6, "ยืนยันรหัสผ่านต้อง 6 ตัวขึ้นไป")
      .oneOf([Yup.ref("password")], "รหัสผ่านไม่ตรงกัน")
      .required("ป้อนยืนยันรหัสผ่าน"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  /*   useEffect(() => {
    var user = localStorage.getItem("id");
    console.log("user: ", user);
    setUserId(user);
  }, []); */
  useEffect(() => {
    var uID = localStorage.getItem("id");
    setUserId(uID);
  }, []);

  function onSubmit(data) {
    setLoading(true);
    // display form data on success
    // alert("เปลี่ยนรหัสผ่านสำเร็จ :-)\n\n" + JSON.stringify(data, null, 4));
    axios
      .post(`${advertUrl}changepwd.php`, {
        pid: userId,
        password: data.password,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
    return false;
  }
  return (
    <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
          แก้ไขรหัสผ่าน
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <label>New Password</label>
            <input
              type="password"
              {...register("password")}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
            <div className="my-3 text-normal text-red-500 text-xs italic">
              {errors.password?.message}
            </div>
          </div>
          <div className="mb-2">
            <label>Confirm Password</label>
            <input
              type="password"
              {...register("confirmPassword")}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
            <div className="my-3 text-normal text-red-500 text-xs italic">
              {errors.confirmPassword?.message}
            </div>
          </div>

          <div className="flex justify-end">
            <button
              disabled={loading}
              type="submit"
              className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
            >
              {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePwd;
