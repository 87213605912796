import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  uUrl,
  imgGovUrl,
  imgPvUrl,
  imgUnUrl,
  govUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const EditUnit = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [scope, setScope] = useState("");
  const [url, setUrl] = useState("");
  const [agency, setAgency] = useState("");
  const [phone, setPhone] = useState("");
  const [myPackage, setMyPackage] = useState("");
  const [locations, setLocations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [userId, setUserId] = useState("");
  // const [scope, setScope] = useState("");

  /*   function readLocation() {
    axios.get(`${uUrl}location.php`).then(function (response) {
      console.log(response.data);
      setLocations(response.data);
    });
  }
  function readPackage() {
    axios.get(`${uUrl}readpackage.php`).then(function (response) {
      console.log(response.data);
      setPackages(response.data);
    });
  } */

  /*   function readData(id) {
    axios
      .get(`${uUrl}readadvertcompanybyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setScope(response.data?.scope);
        setMyPackage(response.data?.packageID);
        setUrl(response.data?.url);
        setAgency(response.data?.agency);
      });
  } */
  function readData(id) {
    axios.get(`${govUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setScope(response.data?.scope);
      setUrl(response.data?.url);
      setAgency(response.data?.agency);
      setPhone(response.data?.phone);
      // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
    });
  }

  useEffect(() => {
    var uid = localStorage.getItem("id");
    setUserId(uid);

    readData(id);
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("scope", scope);
    formData.append("url", url);
    formData.append("agency", agency);
    formData.append("phone", phone);
    formData.append("idEdit", id);
    formData.append("userID", userId); //laborID
    // formData.append("packageID", );
    setLoading(true);
    /*     $userID= $_POST["userID"];
$packageID= $_POST["packageID"]; */
    /*   scope: "",
  url: "",
  agency: "", */
    //updateadvertcompany.php
    //deleteadvertcompany.php
    //addadvertcompany.php
    //readadvertcompany.php
    //readadvertcompanywithid.php;
    axios.post(`${govUrl}updatelabor.php`, formData).then(function (response) {
      console.log(response.data);
      readData(id);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
    });
  };
  return (
    <>
      <div className="">
        <div className="grid gap-2 grid-cols-1 md:grid-cols-2">
          <div className="flex-1">
            {datas?.photo != null ? (
              <img
                src={`${imgGovUrl}${datas?.photo}`}
                className="rounded-lg w-full mb-4 md:h-[300px] object-cover"
              />
            ) : (
              "-"
            )}
          </div>
          <div className="flex-1">
            <form onSubmit={handleOnSubmit}>
              {/*               <div className="mb-2">
                <label>Scope</label>
                <select
                  value={scope}
                  onChange={(e) => setScope(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  <option value="0">All</option>
                  {locations.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.thName}
                      {item.enName ? `(${item.enName})` : ""}-{item.id}
                    </option>
                  ))}
                </select>
              </div> */}
              {/*               <div className="mb-2">
                <label>Package</label>
                <select
                  value={myPackage}
                  onChange={(e) => setMyPackage(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  {packages.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}/({item.price})
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="mb-2">
                <label>Url Web site </label>
                <input
                  type="url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-2">
                <label>Email ลูกค้า</label>
                <input
                  type="text"
                  value={agency}
                  onChange={(e) => setAgency(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-2">
                <label>Phone*</label>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="my-2">
                <label>Photo</label>
                <div className="flex flex-col items-start">
                  <input type="file" onChange={handleOnChange} />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  disabled={loading}
                  type="submit"
                  className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
                >
                  {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUnit;
