import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";
import { Home, AdvertUnit, ChangePwd, Policy, SignIn } from "./pages";
import { AddUnit, EditUnit, ViewUnit } from "./components/advert";
const Layout = () => {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/changepwd",
        element: <ChangePwd />,
      },
      {
        path: "/advertunit",
        element: <AdvertUnit />, //AddUnit, EditUnit, ViewUnit
        children: [
          {
            path: "",
            element: <ViewUnit />,
          },
          {
            path: "addunit",
            element: <AddUnit />,
          },
          {
            path: "editunit/:id",
            element: <EditUnit />,
          },
        ],
      },
    ],
  },
  {
    path: "signin",
    element: <SignIn />,
  },
  {
    path: "/policy",
    element: <Policy />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
