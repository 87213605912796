import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  uUrl,
  imgGovUrl,
  imgPvUrl,
  imgUnUrl,
  govUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
  HiOutlineUsers,
} from "react-icons/hi2";

import { FiSave } from "react-icons/fi";

import { IconName } from "react-icons/bi";

const ViewUnit = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [dataApp, setDataApp] = useState([]);
  const [userId, setUserId] = useState("");
  //updateadvertcompany.php
  //deleteadvertcompany.php
  //addadvertcompany.php
  //readadvertcompany.php
  //   readadvertcompanywithid.php;

  function readAppData(id) {
    axios.get(`${govUrl}readadvertlaborid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }
  /*    function readDistribute() {
    axios.get(`${distributeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setMyData(response.data);
    });
  } */

  useEffect(() => {
    var user = localStorage.getItem("id");
    setUserId(user);
    readAppData(user);
  }, []);

  console.log("Advert: ", myData);

  //localhost:8080/appApi/settings/accounttype/deleteactype.php/1
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${govUrl}del.php/${id}`).then(function (response) {
          console.log(response.data);
          readAppData(userId);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  };
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการโฆษณา
          </h4>
          {/* -----------  */}
          <div className="max-w-full overflow-x-auto">
            {/* --------  */}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[200px] w-full font-medium text-black dark:text-white">
                    รูป
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    รายละเอียด
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    แพ็คเก็ต
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataApp.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      {item.photo != null ? (
                        <img
                          src={`${imgGovUrl}${item.photo}`}
                          className="rounded-lg w-[200px]"
                        />
                      ) : (
                        "-"
                      )}
                      {/*                       <p className="text-sm"> {item?.address}</p>
                  <p className="text-sm"> {item?.phone}</p> */}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.agency}
                      </h5>
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.phone}
                      </h5>
                      <p className="text-sm">
                        {item?.thName}({item?.enName})
                      </p>
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      -
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        <button
                          onClick={() => deleteData(item?.advID)}
                          className="hover:text-primary"
                        >
                          <IoTrashOutline size={22} />
                        </button>
                        <Link to={`editunit/${item?.advID}`} className="">
                          <HiMiniPencilSquare size={22} />
                        </Link>
                        {/*                         <Link to={`viewmembers/${item?.id}`} className="">
                          <HiOutlineUsers size={22} />
                        </Link> */}
                        {/*                         <Link to={`addsalary/${item?.id}`} className="">
                      บันทึกเงินเดือน
                    </Link> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* --------  */}
          </div>
          {/* -----------  */}
        </div>
      </div>
    </>
  );
};

export default ViewUnit;
