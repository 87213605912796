import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

import { imgGovUrl, imgPvUrl, imgUnUrl, govUrl } from "../utils/baseUrl";

import axios from "axios";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [dataApp, setDataApp] = useState([]);
  const [userId, setUserId] = useState("");
  //updateadvertcompany.php
  //deleteadvertcompany.php
  //addadvertcompany.php
  //readadvertcompany.php
  //   readadvertcompanywithid.php;

  function readAppData(id) {
    axios.get(`${govUrl}readadvertlaborid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    var user = localStorage.getItem("id");
    setUserId(user);
    readAppData(user);
  }, []);

  console.log("Advert Data.....: ", dataApp);
  return (
    <>
      {/* <div>u(Labor):{userId}</div> */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        {dataApp.map((item, index) => (
          <div
            key={index}
            className="rounded-sm border border-stroke bg-white py-2 px-2 shadow-default dark:border-strokedark dark:bg-boxdark"
          >
            {item.photo != null ? (
              <img
                src={`${imgGovUrl}${item.photo}`}
                className="rounded-lg w-full"
              />
            ) : (
              "-"
            )}
            <div className="my-4">
              <h5 className="font-medium text-black dark:text-white">
                {item?.agency}
              </h5>
              <p className="text-sm">
                {item?.thName}({item?.enName})
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Home;
